import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 업계 최초 구독형 로밍서비스 출시… ‘여행과 통신 혜택을 한번에’",
  "| 단 한 번 결제로 연간 전세계 로밍 횟수 제한 없이 50% 할인",
  "| 연 9,900원에 파격 혜택, 로밍 경쟁력 업그레이드… ‘타사와 비교 불가’",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/40/40_img1.png" style={{ width: "300px", margin: "auto" }} alt="" />
      </div>
      <div className="desc">&lt;(위) 핀다이렉트 로밍패스&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 자사 통신플랫폼 핀다이렉트(www.pindirectshop.com) 내 구독형 로밍서비스인 ‘로밍패스’를
      출시한다고 6일 밝혔다. 로밍패스는 연간 멤버십 개념으로, 9,900원을 1회만 납부해도 전세계 대상 로밍상품을 횟수 제한 없이 50%
      할인된 가격으로 이용할 수 있는 서비스이다. 스테이지파이브는 로밍패스 런칭을 기념하여 최대 1만 5천원 상당의 웰컴기프트를
      증정한다. 웰컴기프트는 밀크코인*과 카카오페이포인트 중 선택할 수 있다.
      <br />
      <br />
      핀다이렉트 데이터로밍은 출시 후 합리적인 가격과 편의성으로 누적 이용건수가 6만건 이상에 이르는 등 좋은 반향을 얻고 있다. △
      출국 전 사전 액티베이트 △24시간 로밍전용 고객센터 운영 △핀다이렉트 앱 내에서 실시간 잔여데이터 확인 △추가 데이터 필요 시 앱
      내 간편 연장 가능 등은 핀다이렉트만의 강점이다.
      <br />
      <br />
      특히, 합리적인 가격으로 로밍을 이용하고 싶은 고객이라면 로밍패스 가입 시 높은 수준의 혜택을 누릴 수 있다. 일본 방문 기준,
      통신3사의 로밍을 사용할 경우 300-500MB 사용 시 1만원 안팎의 다소 높은 비용이 청구된다. 이에 반해 핀다이렉트 로밍으로 500MB
      사용 시 정상가 3,600원에 사용이 가능하다. 더하여, 로밍패스에 가입하면 50% 할인 혜택을 받아 최종 1,800원에 사용이 가능한
      셈이다. 이는 타사 대비 월등한 수준으로, 매우 경쟁력 있는 가격이다.
      <br />
      <br />
      로밍패스 가입자는 위와 같은 파격적 반값할인 혜택을 연간 무제한으로 받을 수 있다. 이는 업계 최초 구독형 로밍서비스로,
      핀다이렉트 로밍 고객은 해외에서도 데이터와 비용 걱정 없이 사용할 수 있도록 차별화하여 선보이는 서비스이다.
      <br />
      <br />
      스테이지파이브 관계자는 “로밍패스는 구독형 서비스에 여러 혜택을 담아 여행과 통신의 최적점을 담기 위해 기획했다. 이후에도
      여행자를 위한 다양한 혜택을 계속 추가할 예정“이라며 “기존 로밍 패러다임을 혁신하기 위해, 테크 측면에서도 지속적으로 연구할
      것”이라 말했다.
      <br />
      <br />
      한편, 스테이지파이브는 지난 2022년 12월 인터파크트리플과 함께 핀다이렉트 데이터로밍을 출시하여 6개월간 75만뷰를 기록하는 등
      폭발적 관심을 받은 바 있다. 앞선 15일에는 야놀자로부터 전략적 투자를 유치했다.
      <br />
      *밀크 (https://www.milkplay.com)
      <br />
      <br />
      밀크(MiL.k)는 블록체인 기반 포인트 통합 플랫폼으로 현재 145만명 이상이 사용 중. 밀크(MiL.k) 사용자는 밀크(MiL.k) 상에서
      파트너사의 마일리지나 포인트를 상호 교환하는 과정에서 밀크코인(MLK)을 사용할 수 있음. 밀크파트너스와 함께 하고 있는 주요
      파트너사로는 야놀자, 인터파크, 롯데 엘포인트, SK OK캐시백, 진에어, 신세계면세점, CU 등이 있음.
      <br />
    </p>
  </div>
)

const press40 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 9월 6일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 구독형 로밍서비스 ‘로밍패스’ 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press40
